import React from "react"
import Layout from "../components/layout/Layout"
import Breadcrumbs from "../components/nationalpark/Breadcrumbs"
import MetaData from "../components/seo/MetaData"
import { Shelter, NationalPark } from "../models/NationalPark"
import ShelterSidebar from "../components/sidebar/ShelterSidebar"
import MapSection from "../components/maps/MapSection"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  data: Shelter
  park: NationalPark
}

const ShelterTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  pageContext.park.shelters = pageContext.park.shelters.filter(
    item => item.shelter_name[0].text !== pageContext.data.shelter_name[0].text
  )

  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.shelter_name[0].text} - ${pageContext.park.national_park_name[0].text}`}
        description={`${pageContext.data.shelter_name[0].text} in ${pageContext.park.national_park_name[0].text}. Read more about the shelter and get directions to visit.`}
        thumbnailUrl={pageContext.data.shelter_image.url}
        imageAlt={`${pageContext.data.shelter_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className={`container mx-auto px-4`}>
        <Breadcrumbs
          continent={pageContext.park.continent}
          country={pageContext.park.country}
          region={pageContext.park.region[0].text}
          parkName={pageContext.park.national_park_name[0].text}
        />
        <h1 className="mt-2 mb-6">{pageContext.data.shelter_name[0].text}</h1>
      </div>
      <div className={`container mx-auto px-4 grid grid-cols-12 gap-4`}>
        <div className="md:col-span-8 col-span-12">
          <img
            src={pageContext.data.shelter_image.url}
            alt={pageContext.data.shelter_image.alt}
            loading="eager"
          />
          <p>{pageContext.data.shelter_description[0].text}</p>
        </div>
        <ShelterSidebar title="Details" shelter={pageContext.data} />
      </div>
      <div className="col-span-12">
        <MapSection
          parkname={pageContext.data.shelter_name[0].text}
          coordinates={pageContext.data.shelter_location}
        />
      </div>
    </Layout>
  )
}

export default ShelterTemplate
