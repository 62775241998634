import React from "react"
import { AiOutlineLink } from "react-icons/ai"
import { FiMenu } from "react-icons/fi"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import Colors from "../../constants/Colors"
import { Shelter } from "../../models/NationalPark"
import CustomCard from "../cards/CustomCard"

interface Props {
  title: string
  shelter: Shelter
}

export default function ShelterSidebar({ title, shelter }: Props) {
  return (
    <div className="col-span-12 md:col-span-4 pr-6">
      <CustomCard className="pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
        <h4 className="mt-0 mb-3">{title}</h4>
        <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
          <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
            Type
          </p>
          <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-20 font-bold">
            {shelter.shelter_type}
          </p>
        </div>
        {shelter.shelter_link !== undefined && (
          <div className="rounded-sm pt-2 pb-1 px-2">
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
              Website Link
            </p>
            <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-20 font-bold">
              <AiOutlineLink
                size={14}
                className="inline-block"
                color={Colors.themeColor}
                style={{ marginBottom: 2, marginRight: 5 }}
              />
              <a
                href={shelter.shelter_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </p>
          </div>
        )}
      </CustomCard>
    </div>
  )
}
